.switch-container {
  display: flex;
  flex-direction: row;
  padding: 0 10px;

  .switch-item {
    width: 50px;
    position: relative;
    overflow: hidden;
    flex: none;
    display: flex;
    flex-direction: column;
    background: var(--card-bg);
    color: var(--card-color);
    padding: 2px;
    padding-bottom: 4px;
    padding-top: 12px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    margin: 0 2px;
    top: -10px;

    .moves {
      position: relative;
      flex: 1;
      align-items: center;
      display: flex;
      flex-direction: row;
      width: 100%;

      .switch-icon {
        flex: 1;
        display: inline-block;
        height: 20px;
        min-width: 20px;
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;

        &.dead {
          background-image: url('./assets/kill.png');
        }

        &.hit {
          background-image: url('./assets/hit.png');
        }

        &.air {
          background-image: url('./assets/air.png');
        }
      }
    }
    .progress {
      position: relative;
      display: flex;
      flex-direction: row;

      .player-progress {
        flex: 1;
        display: flex;

        &.player-side {
          justify-content: center;
        }
        &.other-side {
          justify-content: center;
        }
        .progress-pip {
          display: block;

          &::after {
            content: "";
            background: black;
            display: block;
            width: 4px;
            height: 4px;
            border-radius: 4px;
            margin: 0 1px;
          }
        }
      }
    }

    .timeout {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      background: rgba(0, 0, 0, 0.5);
      z-index: 0;
    }

    &.gameend {
      text-align: center;
      font-weight: bold;
      justify-content: center;
    }

    &.player-turn {
      background: pink;
    }

    &.other-turn {
      background: lightgreen;
    }

    &.current {
      top: 0;
    }
  }
}
