@font-face {
  font-family: 'Kreon';
  font-style: normal;
  font-weight: normal;
  src: url('./assets/Kreon-Regular.ttf');
}

@font-face {
  font-family: 'Kreon';
  font-style: normal;
  font-weight: bold;
  src: url('./assets/Kreon-Bold.ttf');
}

@font-face {
  font-family: 'FiraSans';
  font-style: normal;
  font-weight: normal;
  src: url('./assets/FiraSans-Regular.ttf');
}
@font-face {
  font-family: 'FiraSans';
  font-style: normal;
  font-weight: 500;
  src: url('./assets/FiraSans-Medium.ttf');
}
@font-face {
  font-family: 'FiraSans';
  font-style: normal;
  font-weight: bold;
  src: url('./assets/FiraSans-Bold.ttf');
}



// LIGHT MODE
// --text: #000000;
// --background: #ecf3f4;
// --primary: #2b3648;
// --secondary: #d8e6e9;
// --accent: #58283a;

:root {
  --ion-background-color: #ecf3f4;
  // --play-bg: rgba(0, 0, 0, 0.2);
  --play-bg: #d8e6e9;
  --play-border: rgba(0, 0, 0, 0.2);
  --play-air: rgb(224, 255, 255);
  --play-hit: pink;
  --play-dead: red;
  --play-latest: rgb(252, 250, 56);
  
  --card-bg: #2b3648;
  --card-border-radius: 15px;
  --card-padding: 15px;
  --card-color: #fff;
}

// DARK MODE
// --text: #ecf3f4;
// --background: #000000;
// --primary: #2b3648;
// --secondary: #0f181a;
// --accent: #b76281;

@media (prefers-color-scheme: dark) {
  * {
    --ion-background-color: #000000;
    --ion-item-background: #000000;

    --play-bg: #0f181a;
    --play-border: rgba(236, 243, 244, 0.2);
    --play-air: rgb(224, 255, 255);
    --play-hit: pink;
    --play-dead: red;
    --play-latest: rgb(252, 250, 56);
    
    --card-bg: #2b3648;
    --card-border-radius: 15px;
    --card-padding: 15px;
    --card-color: #ecf3f4;

  }
}


ion-app {
  .offline-indicator {
    position: fixed;
    bottom: 0;
    height: 21px;
    width: 100%;
    background: orange;
    color: black;
    padding: 3px;
    text-align: center;
    display: none;
  }
  
  &.offline {
    &>.ion-page {
      margin-bottom: 21px;
    }
    .offline-indicator {
      display: block;
    }
  }
}
