.play-page {
  .grid {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    gap: 0px;

    .item {
      display: flex;
      flex-direction: column;
      overflow: hidden;

      outline: 1px solid var(--play-border);
      outline-offset: -1px;
    }
  }

  .toucharea {
    touch-action: manipulation;
  }


  &.game-over {
    .toucharea {
      touch-action: initial;
    }
  }


  .avioane {
    position: relative;

    &.main {
      width: calc(100vw - 20px);
      height: calc(100vw - 20px);
      // overflow: hidden;
      background-color: var(--play-bg);
      margin: 10px;
    }

    .item {
      overflow: visible;
      position: relative;


      &.queued {
        //background: red;
        position: relative;
        &::after {
          display: block;
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        
        &.queued-1::after {
          content: "1";
        }
        &.queued-2::after {
          content: "2";
        }
        &.queued-3::after {
          content: "3";
        }
        &.queued-4::after {
          content: "4";
        }
        &.queued-5::after {
          content: "5";
        }
        &.queued-6::after {
          content: "6";
        }
        &.queued-7::after {
          content: "7";
        }
        &.queued-8::after {
          content: "8";
        }
        &.queued-9::after {
          content: "9";
        }
        &.queued-10::after {
          content: "10";
        }
      }
      &.air,
      &.hit,
      &.dead {

        .x-helper,
        .y-helper {
          display: none;
        }
      }

      .x-helper,
      .y-helper {
        color: var(--play-border);
        text-align: center;
        text-transform: uppercase;
        pointer-events: none;

      }

      .x-helper {
        position: absolute;
        top: 2px;
        right: 3px;
      }

      .y-helper {
        position: absolute;
        left: 3px;
        bottom: 2px;
      }
    }

  }

  .main-parent {
    position: relative;
    width: 100vw;
    height: 100vw;

    .main {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
    }

    .main.background {
      z-index: 1;
    }
  }

  @keyframes pulse {
    0% {
      transform: scale(0.8);
      // box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
    }

    50% {
      transform: scale(1.2);
      // box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
    }

    100% {
      transform: scale(1);
      // box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }
  }

  @keyframes gradient {
    0% {
      background-position: 0% 50%;
    }

    50% {
      background-position: 100% 50%;
    }

    100% {
      background-position: 0% 50%;
    }
  }


  @keyframes shrink {
    0% {
      visibility: visible;
      opacity: 1;
      transform: scale(1);
    }

    80% {
      opacity: 1;
    }

    99% {
      visibility: visible;
      transform: scale(0.2);
      opacity: 0;
    }

    100% {
      visibility: visible;
      opacity: 0;
    }
  }


  .secondary-container {
    position: relative;
    width: 50vw;
    height: 50vw;
    background-color: var(--play-bg);

    &::after {
      content: " ";
      z-index: 100;
      position: absolute;
      top: 10%;
      left: 10%;
      width: 80%;
      height: 80%;

      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;

      visibility: hidden;

    }

    &.Hit {
      &::after {
        background-image: url('../assets/hit.png');
        animation: 1.5s normal shrink;

      }
    }

    &.Air {
      &::after {
        background-image: url('../assets/air.png');
        animation: 1.5s normal shrink;

      }
    }

    &.Dead {
      &::after {
        background-image: url('../assets/kill.png');
        animation: 1.5s normal shrink;

      }
    }
  }

  .avioane.play {
    &.secondary.foreground {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 16;

      background-image: none;

      .item {
        background-image: none;
        outline: none;
      }
    }

    &.secondary.background {
      position: absolute;
      top: 0;
      left: 0;
      background: none;

      .item {
        background: none;
      }
    }

    &.secondary.background-background {
      position: absolute;
      top: 0;
      left: 0;
    }

    .item {
      box-sizing: border-box;

      &.even {
        // background-color: rgba(0, 0, 0, 0.1);
      }

      &.red {
        background-color: #F36B6B;
      }

      &.green {
        background-color: #4FCE4C;

      }

      &.orange {
        background-color: #F3D56B;
      }

      &.plane {
      }

      &.head {

        overflow: visible;
        z-index: 10;

        &::before {
          pointer-events: none;
          // opacity: 0.5;
          position: absolute;
          content: " ";
          background-image: url('../assets/plane.png');
          background-size: 100%;
          background-repeat: no-repeat;
          width: 500%;
          height: 500%;
          left: -200%;
          top: 0%;

        }

        &.South {
          &::before {
            transform: rotate(180deg);
            left: -200%;
            top: -400%;
          }
        }

        &.West {
          &::before {
            transform: rotate(270deg);
            left: 0;
            top: -200%;
          }
        }

        &.East {
          &::before {
            transform: rotate(90deg);
            left: -400%;
            top: -200%;
          }
        }
      }

      &.hit {
        display: flex;
        align-items: center;
        justify-content: center;

        &::after {
          content: "";
          position: absolute;
          top: 10%;
          left: 10%;
          width: 80%;
          height: 80%;
          z-index: 2;
          background-image: url('../assets/hit.png');

          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
        }
      }

      &.dead {
        display: flex;
        align-items: center;
        justify-content: center;

        &::after {
          content: "";
          position: absolute;
          top: 5%;
          width: 100%;
          height: 100%;
          background-image: url('../assets/kill.png');
          z-index: 2;
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;

        }
      }

      &.air {
        display: flex;
        align-items: center;
        justify-content: center;

        &::after {
          content: "";
          position: absolute;
          top: 10%;
          left: 10%;
          width: 80%;
          height: 80%;
          background-image: url('../assets/air.png');
          z-index: 2;

          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
        }
      }

      &.latest {
        // background: linear-gradient(90deg, rgba(252,250,56,1) 0%, rgba(252,250,56,1) 35%, rgba(68,168,248,1) 39%, rgba(68,168,248,1) 44%, rgba(252,250,56,1) 47%, rgba(252,250,56,1) 100%);
        // background-size: 400% 400%;
        // animation: gradient 2s ease infinite;


        background-image: none;
        background-color: var(--play-latest) !important;

        &::after {
          animation: 0.5s normal pulse;
        }

      }
    }
  }


  .play-options {
    margin: 0 10px;
  }


  .bottom {
    margin-top: 10px;
    display: flex;
    justify-content: center;

    .avioane.secondary {
      width: calc(50vw);
      height: calc(50vw);
    }

    .move-counter {
      padding: 0 10px;

      div {
        display: flex;
        align-items: center;
        margin-bottom: 10px;

        &::before {
          display: inline-block;
          position: relative;
          width: 2em;
          height: 2em;
          content: " ";
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
          margin-right: 5px;
        }
      }

      .dead {
        &::before {
          background-image: url('../assets/kill.png');
        }
      }

      .hit {
        &::before {
          background-image: url('../assets/hit.png');
        }
      }

      .air {
        &::before {
          background-image: url('../assets/air.png');
        }
      }
    }
  }


  @keyframes fadeOut {
    0% {
      visibility: visible;
      opacity: 1;
    }

    99% {
      visibility: visible;
      opacity: 0;
    }

    100% {
      visibility: visible;
      opacity: 0;
    }
  }

  &.my-turn {

    .main {
      &::before {
        content: "Your turn";
        visibility: visible;
        opacity: 0.1;
        display: flex;
        position: absolute;
        pointer-events: none;
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: center;
        font-size: 30px;
      }
    }
  }


  &.other-turn {
    // --background: rgb(254, 211, 218);

    .main {
      &::after {
        content: "Opponent's turn";
        display: flex;
        position: absolute;
        width: 100%;
        background-color: rgba(254, 211, 218, 0.2);
        pointer-events: none;
        height: 100%;
        align-items: center;
        justify-content: center;
        font-size: 30px;
        z-index: 10;
      }
    }
  }

  .onlineIndicator {
    font-size: 0.8em;
    margin-right: 10px;
    color: gray;

    &.online {
      color: forestgreen;
    }
  }


}

.draw-container {
  display: flex;
  align-items: center;
  padding: 0 10px;
  background-color: var(--card-bg);
  color: var(--card-color);
  height: 40px;
  border-top: 2px solid var(--play-border);

  .settings {
    float: right;
    flex: none;

    ion-icon {
      padding-left: 10px;
    }
  }
}

.draw-bar {
  overflow: visible;
  display: inline-block;
  height: 38px;
  overflow: hidden;
  flex: 1;

  button {
    position: relative;
    width: 57px;
    height: 38px;
    background: none;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    transform: rotate(-30deg);
    margin-left: 10px;

    &.red {
      background-image: url('../assets/cr-1.png');
    }

    &.yellow {
      background-image: url('../assets/cr-2.png');
    }

    &.green {
      background-image: url('../assets/cr-3.png');
    }
  }
}