.player {
    width: 30vw;
    height: 30vw;
    left: 0px;
    flex: none;
    // outline: 2px solid red;
    // background: grey;
    position: relative;

    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;

    &.plane-b {
        background-image: url('../assets/plane-b.png');
    }

    &.plane-r {
        background-image: url('../assets/plane-r.png');
    }

    &.plane-y {
        background-image: url('../assets/plane-y.png');
    }

}

.icon-item {
    display: flex;
    align-items: center;
    border-bottom: rgba(128, 128, 128, 0.5);
    padding: 5px 0;

    &.selected {
        background-color: rgba(128, 128, 128, 0.5);
    }
}