.draw-page {

  .grid {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    gap: 0px;

    .item {
      display: flex;
      flex-direction: column;
      overflow: hidden;
      box-sizing: border-box;
      outline: 1px solid var(--play-border);
      outline-offset: -1px;
      box-sizing: border-box;
    }
  }

  .main-container {
    position: relative;
    width: calc(100vw - 20px);
    height: calc(100vw - 20px);
    // overflow: hidden;
    background-color: var(--play-bg);
    margin: 10px;

    overflow: hidden;

    .avioane {
      position: absolute !important;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    .overlap-layer {
      z-index: 2;
      pointer-events: none;

      .item {
        background: none;
        outline: none;
        &.overlap {
          background-image: none !important;
          background-color: blue !important;
        }
      }
    }

    .main {
      z-index: 1;

      .item {
        background: none;
      }
    }

    .main-background {
      z-index: 0;
    }
  }

  .avioane {
    position: relative;

    &.main {
      // width: calc(100vw);
      // height: calc(100vw);
      overflow: hidden;
    }

    .item {
      overflow: visible;
      position: relative;

      .x-helper,
      .y-helper {
        color: var(--play-border);
        text-align: center;
        text-transform: uppercase;
        pointer-events: none;

      }

      .x-helper {
        position: absolute;
        top: 2px;
        right: 3px;
      }

      .y-helper {
        position: absolute;
        left: 3px;
        bottom: 2px;
      }
    }

  }

  ion-content {
    user-select: none;
  }

  .toucharea {}

  .touch-overlay {
    margin-top: 1px;
    position: relative;
    touch-action: none;
  }

  .dropzone {
    z-index: 3;
    background-color: rgb(255, 0, 0, 0.3);
    // opacity: 0;
    // background-color: inherit;

    .item {
      background-image: none;
    }
  }

  .avioane {
    .item {
      overflow: visible;
      position: relative;

      &.plane {
        background-color: pink;
      }

      &.preview-head {
        position: relative;
        z-index: 11;
        // background-color: red;

        overflow: visible;

        &::before {
          pointer-events: none;
          position: absolute;
          content: " ";
          background-image: url('../assets/plane.png');
          background-size: 100%;
          background-repeat: no-repeat;
          width: 500%;
          height: 500%;
          left: -200%;
          top: 0%;

        }

        &.preview-South {
          &::before {
            transform: rotate(180deg);
            left: -200%;
            top: -400%;
          }
        }

        &.preview-West {
          &::before {
            transform: rotate(270deg);
            left: 0%;
            top: -200%;
          }
        }

        &.preview-East {
          &::before {
            transform: rotate(90deg);
            left: -400%;
            top: -200%;
          }
        }
      }

      &.head {

        overflow: visible;
        z-index: 10;

        &::after {
          pointer-events: none;
          position: absolute;
          content: " ";
          background-image: url('../assets/plane.png');
          background-size: 100%;
          background-repeat: no-repeat;
          width: 500%;
          height: 500%;
          left: -200%;
          top: 0;

        }

        &.South {
          &::after {
            transform: rotate(180deg);
            left: -200%;
            top: -400%;
          }
        }

        &.West {
          &::after {
            transform: rotate(270deg);
            left: 0;
            top: -200%;
          }
        }

        &.East {
          &::after {
            transform: rotate(90deg);
            left: -400%;
            top: -200%;
          }
        }
      }
    }


    &.background {
      .item {
        overflow: visible;
        position: relative;

        &.plane {
          background-color: pink;
        }

        &.head {
          background-color: red;
        }

        &.overlap {
          background-color: blue;
        }
      }

      .item.item-preview {
        &.overlap {
          background-color: blue;
        }
      }
    }
  }

  .preview {
    width: calc(100vw - 20px);
    margin: 10px;
    display: grid;
    gap: 10px;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr;

    .selected {}

    .grid.mini {
      height: 20vw;
      width: 20vw;
      background-color: var(--play-bg);

      grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
      grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
    }
  }
}