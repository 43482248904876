.player-row {

    margin: 20px 0;
    font-family: 'Kreon', serif;
    font-size: 25px;
    height: calc(30vw - 20px);
    // background-image: url('../assets/player-bg.png');
    background-color: var(--card-bg);
    color: var(--card-color);
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  
    .player-item {
      display: flex;
      flex-direction: row;
      margin-bottom: 10px;
      height: calc(30vw - 20px);
      position: relative;
  
      &>.player {
        height: 30vw;
        width: 30vw;
        display: block;
        flex: none;
        position: absolute;
        top: -10px;
        left: 10px;

        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;


        &.plane-b {
          background-image: url('../assets/plane-b.png');
        }
        &.plane-r {
          background-image: url('../assets/plane-r.png');
        }
        &.plane-y {
          background-image: url('../assets/plane-y.png');
        }
      }
  
      .awards {
        div {
          display: inline-block;
          width: 20pt;
          height: 20pt;
        }
  
        .award-1 {
          background-image: url('../assets/kill.png');
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
        }
      }
  
      .details {
        flex: 1;
        padding: 0 10px;
        display: flex;
        flex-direction: column;
        height: 100%;
        margin-left: calc(30vw + 10px);
  
        .opponent,
        .you {
          flex: 1;
          display: flex;
        }
  
        .opponent {
          align-items: flex-start;
        }
  
        .you {
          align-items: flex-end;
          justify-content: flex-end
        }
      }
    }
  
    .player-history {
      display: flex;
  
      .opponent,
      .you {
        flex: 1;
        display: flex;
      }
  
      .opponent {
        justify-content: flex-start
      }
  
      .you {
        justify-content: flex-end
      }
    }
  }