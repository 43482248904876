
.dashboard-page {
  .play-option.description {

    div:nth-of-type(1),
    ion-label {
      white-space: initial;
      font-size: medium;
    }
  }

  .play-option.title {
    ion-label {
      text-decoration: underline;
      color: var(--card-color);
    }
  }

  .actions ion-button {

    // --ion-font-family: 'Fira Sans', sans-serif;
    text-transform: uppercase;
    font-weight: bold;
    font-size: small;
    margin: 0 4px;

  }

  .actions {
    display: flex;
    justify-content: center;
    width: 100%;
    // bottom: -17px;
    // right: 10px;
    z-index: 1;
  }

  .matchmaking-row {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    padding: var(--card-padding);
    font-family: 'Fira Sans', sans-serif;

    border-radius: var(--card-border-radius);

    background: var(--card-bg);

    justify-content: center;
    align-items: center;
    position: relative;
    color: var(--card-color);
    
    display: flex;
    flex-direction: column;

    .content-main {
      flex: auto;
    }

    .actions {
      flex: 1;
    }

    .time {
      font-size: x-large;
      display: flex;
      font-weight: bold;
      justify-content: center;
      align-items: center;
      padding: 5px;
    }


    .status {
      font-size: medium;
      display: flex;
      justify-content: center;
      align-items: center;
    }

  }
  .accept-match-row {
    font-family: 'Fira Sans', sans-serif;
    width: calc(100% - 20px);
    margin: 5px auto;
    background: var(--card-bg);
    border-radius: var(--card-border-radius);
    color: var(--card-color);

    justify-content: center;
    align-items: center;
    position: relative;
    padding: var(--card-padding);

    .time {
      font-size: x-large;
      display: flex;
      font-weight: bold;
      justify-content: center;
      align-items: center;
      padding: 5px;
    }


    .status {
      font-size: medium;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .accept-match-row {
    .status {
      font-size: x-large;
    }
  }

  a {
    text-decoration: none;
    color: var(--card-color);
  }

  .play-option-row {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    padding: var(--card-padding);
    font-family: 'Fira Sans', sans-serif;

    border-radius: var(--card-border-radius);
    // border: 1px solid var(--card-border);
    // box-shadow: 1px 1px var(--card-border);

    background: var(--card-bg);
    // border-top: 2px solid var(--card-bg-light);
    // border-bottom: 4px solid var(--card-bg-dark);

    justify-content: center;
    align-items: center;
    position: relative;
    // color: var(--card-color);

    .title {
      font-size: large;
      display: flex;
      align-items: center;
      padding-bottom: 5px;
      color: var(--card-color);
    }

    .subtitle {
      // font-size: large;
      display: flex;
      align-items: center;
      color: var(--card-color);

      a {
        text-decoration: underline;
        font-weight: bold;
      }

      input {
        text-align: center;
      }
    }
  }


  .room-row {
    font-family: 'Fira Sans', sans-serif;
    width: calc(100% - 20px);
    margin: 10px auto;
    background: var(--card-bg);
    padding: 10px 5px;
    position: relative;
    font-family: 'Fira Sans', sans-serif;
    border-radius: var(--card-border-radius);
    margin-bottom: 20px;

    .main {
      display: flex;
      flex-direction: row;
      color: var(--card-color);
      height: calc(30vw - 30px);
    }

    .player {
      width: 30vw;
      height: 30vw;
      top: -5px;
      left: 0px;
      flex: none;
      // outline: 2px solid red;
      // background: grey;
      position: absolute;

      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;

      &.plane-b {
        background-image: url('../assets/plane-b.png');
      }
      &.plane-r {
        background-image: url('../assets/plane-r.png');
      }
      &.plane-y {
        background-image: url('../assets/plane-y.png');
      }

    }

    .content {
      margin-left: calc(30vw + 10px);
      height: calc(30vw - 30px);
      flex: 1;
      display: flex;
      flex-direction: column;

      .content-main {
        flex: 1;
      }
      .actions {
        flex: none;
        justify-content: flex-start;
        
        ion-button:first-child {
          margin-left: 0;
        }
      }

      .player-name {
        font-size: large;
        font-weight: bold;
        display: flex;
        align-items: center;
      }


      .subtitle {
        font-size: medium;
        display: flex;
        align-items: center;
      }

    }
  }

  .button-grid-main {
    display: flex;
    flex-direction: column;
    margin: 10px;

    &>* {
      margin-bottom: 5px;
    }

    .button-grid-top {
      flex: 3;
      display: flex;
      flex-direction: row;
      margin-bottom: 5px;
      
      .matchmaking-btn {
        flex: 3;
        .play-option-row .title {
          font-size: 2.5em;
        }
      }
      
      .button-grid-top-right {
        flex: 2;
        display: flex;
        flex-direction: column; 
        margin-left: 5px;
        .daily-btn {
          flex: 1;
        }
        
        .leaderboard-btn {
          flex: 1;
          margin-bottom: 5px;
        } 
      }
    }
  }
}